<template>
  <div class="carousel">备用
  </div>
</template>

<script>
import { core } from '@/config/pluginInit'
export default {
  name: "carousel",
  components: {
  },
  data() {
    return {
    }
  },
  created() {
  },
  mounted() {
    core.index()
  },
  methods: {
  }
}
</script>

<style lang="less" scoped>
  .carousel {
  }
</style>

